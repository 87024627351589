/* bold text*/
@font-face {
    font-family: 'Century Gothic Bold';
    font-style: normal;
    font-weight: 700;
    src: url(assets/fonts/CenturyGothic-Bold.woff2) format('woff2'), url(assets/fonts/CenturyGothic-Bold.woff) format('woff');
}
/* regular text */
@font-face {
    font-family: 'Century Gothic';
    font-style: normal;
    font-weight: 400;
    src: url(assets/fonts/CenturyGothic.woff2) format('woff2'), url(assets/fonts/CenturyGothic.woff) format('woff');
}

html, body {
    padding: 0;
    margin: 0;
    position: relative;
    display: block;
}

body {
    font-family: "SF-UI-Display-Regular", sans-serif;
    font-weight: 400;
    color: #000000;
    overflow-x: hidden;
}

a:hover, a:focus, a:active {
    color: #f56c6c !important;
    text-decoration: none !important;
    box-shadow: none !important;
}

a.btn.btn-info, button.btn.btn-info {
    background: #ffdd70;
    color: #ffffff;
    padding: 10px 25px;
    border: 0px;
    border-radius: 0px;
}

p, h1, h2, h3, h4, h5, h6 { margin: 0 0 10px 0; }
p { line-height: 1.6; }
h1, h2, h3, h4, h5, h6 { font-weight: 700; font-family: "SF-UI-Display-Semibold", sans-serif; }
h1 { font-size: 36px; }
h2 { font-size: 32px; }
h3 { font-size: 28px; }
h4 { font-size: 24px; }
h5 { font-size: 20px; }
h6 { font-size: 16px; }
ul {
    padding-left: 0px;
    list-style: none;
}

img { max-width: 100%; }

.vertical-orientation {
    writing-mode: vertical-lr !important;
    text-orientation: mixed !important;
}

.service-button {

    position: relative;
    overflow: hidden;
    background: none !important;


    &:hover {

        box-shadow: none !important;

        &:after {
            animation: sheen 1s forwards;
            content: '';
            position: absolute;
            top: -50%;
            right: -50%;
            bottom: -50%;
            left: -50%;
            background: linear-gradient(to bottom, rgba(229, 172, 142, 0), rgba(255, 255, 255, 0.5) 50%, rgba(229, 172, 142, 0));
            transform: rotateZ(60deg) translate(-5em, 7.5em);
        }
    }
}

.footer ul {
    @media (max-width: 991px) {
        display: block;
    }

    @media (max-width: 768px) {
        display: block;
    }
}

.text-underline {
    position: absolute;
    left: 0px;
    bottom: 0px;
    max-height: 2px;
    overflow: hidden;
}


@keyframes sheen {
    100% {
        transform: rotateZ(60deg) translate(1em, -9em);
    }
}